<template>
  <div class="c_submit_paper">
    <div class="stu-module-header">
      <div class="stu-module-title">
        提交论文（提交时间：{{ submitTime || "--" }}）
      </div>
      <div>
        <el-button @click="submitBtn" type="primary">提交</el-button>
        <!-- <el-button @click="dialog = true" type="primary"
          >论文提交说明</el-button
        > -->
      </div>
    </div>
    <el-table
      :data="datalist"
      v-loading="loading"
      :height="500"
      ref="tableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="提交时间"
        align="center"
        min-width="120px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="stuffInfo"
        label="审核评语"
        align="center"
        min-width="180px"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- auditState -->
      <el-table-column
        prop="paperStatus"
        label="审核状态"
        align="center"
        min-width="100px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.paperStatus | statusFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180px">
        <template slot-scope="scope">
          <el-button type="primary" @click="detail(scope.row)">详情</el-button>
          <el-button type="primary" @click="revoke(scope.row)">撤销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>

    <el-dialog title="论文提交说明" :visible.sync="dialog" width="40%">
      <el-form ref="applyDialogForm" :model="form" size="medium">
        <el-form-item label="论文上传要求及抽查名单" prop="list">
          <el-input
            v-model="form.list"
            type="textarea"
            readonly
            class="c_submit_paper_textare"
            :autosize="{ minRows: 8, maxRows: 10 }"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="download">下载抽查名单</el-button>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog :visible.sync="detailDialog" title="查看详情" width="30%">
      <el-form :model="selectRow" label-width="90px">
        <el-form-item label="论文附件：">
          <el-link
            v-if="selectRow.attachmentUrl"
            :href="selectRow.attachmentUrl"
            target="_blank"
            type="primary"
            >查看</el-link
          >
          <span v-else>暂无附件</span>
        </el-form-item>
        <el-form-item label="论文评语：">
          <span></span>
          <span>{{ selectRow.stuffInfo }}</span>
        </el-form-item>
        <el-form-item label="审核时间：">
          <span>{{ selectRow.updateTime }}</span>
        </el-form-item>
        <el-form-item label="审核状态：">
          <span>{{ selectRow.paperStatus | statusFilter }}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 提交 -->
    <el-dialog :visible.sync="submitDialog" title="提交论文" width="30%">
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="`${url}hnjxjy-core/eduGraduateDesignStu/addEduGraduateDesignStudentById`"
        :data="{
          applyFree: 0,
          courseId: course.id,
        }"
        :headers="{
          'x-token': auth['x-token'],
          'x-perm': auth['x-perm'],
        }"
        :on-change="onChange"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary">
          选取文件
        </el-button>
        <p style="color: red;font-size: 14px; display: inline-block; margin-left: 10px; font-weight: bold;">仅支持office文件格式</p>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit" :loading="btnLoading">
          提 交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  SelectEduGraDesStuByStuId,
  SelectEduGraDesStuById,
  AddEduGraduateDesignStudentById,
  QueryBatchByStudentId,
} from "@/libs/api/topic";
import { BASE_URL } from "@/libs/domain";
export default {
  name: "c_submit_paper",
  data() {
    return {
      loading: false,
      btnLoading: false,
      tableData: [],
      pageSize: 10,
      current: 1,
      total: 0,
      submitTime: null,
      selectRow: {},

      form: { list: null },
      dialog: false,
      detailDialog: false,
      submitDialog: false, // 提交
      fileList: [],
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
    url() {
      return BASE_URL;
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
  },
  filters: {
    // 0未提交 1等待审核 2审核通过 3驳回
    statusFilter(value) {
      return value === 0
        ? "未提交"
        : value === 1
        ? "等待审核"
        : value === 2
        ? "审核通过"
        : value === 3
        ? "驳回"
        : "";
    },
  },
  mounted() {
    this.getSubTime();
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      SelectEduGraDesStuByStuId({
        page: this.current,
        pageSize: this.pageSize,
      })
        .then((res) => {
          // this.submitTime = "";
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getSubTime() {
      QueryBatchByStudentId().then((res) => {
        this.submitTime = `${res.data.paperStartTime} - ${res.data.paperEndTime}`;
      });
    },
    detail(row) {
      if (row) {
        this.detailDialog = true;
        SelectEduGraDesStuById({ id: row.id }).then((res) => {
          this.selectRow = res.data;
        });
      }
    },
    revoke(row){
      this.openLoadingView();
      this.$axios_supermallData({
        url: "hnjxjy-core/eduGraduateDesignStu/deleteEduGraduateDesignStudentById",
        method: 'get',
        params: {
          id: row.id
        }
      }).then(resp => {
        if (resp.data.code === 2000){
          this.$message.success("撤销成功");
        }
        this.init();
        this.loadingView.close();
      }).catch(err => {
        this.loadingView.close();
      });
    },
    submit() {
      if (!this.fileList.length) return this.$message.error("请选择文件");
      this.btnLoading = true;
      this.$refs.upload.submit();
    },
    isDuringDate(beginDateStr, endDateStr) {
      if (!beginDateStr || !endDateStr) return true;
      let curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    submitBtn() {
      if (this.submitTime) {
        const time = this.submitTime.split(" - ");
        let isDuringDate = this.isDuringDate(time[0], time[1]);
        if (!isDuringDate)
          return this.$message.error("不在提交时间内，无法提交");
      }
      this.submitDialog = true;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    onChange(file, fileList) {
      this.fileList = fileList;
    },
    handleSuccess(res) {
      this.btnLoading = false;
      this.$refs.upload.clearFiles();
      if (res.code === 2000) {
        this.submitDialog = false;
        this.$message.success("提交成功");
        this.init();
      } else {
        this.$message.error(res.message);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    download() {},
  },
};
</script>

<style scoped lang="less">
.c_submit_paper {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    margin-bottom: 20px;
  }
}
</style>
<style lang="less">
.c_submit_paper {
  .c_submit_paper_textare {
    .el-textarea__inner {
      resize: none;
    }
  }
}
</style>